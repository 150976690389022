:root {
    /* font-size: 16px; */ /* (A)em * 1.6 => (B)rem */
    --font-size-xs: 1.2rem; /*0.75em;*/ /*1.2rem at 10px == 12px == 0.75em at 16px*/
    --font-size-sm: 1.4rem; /*0.875em;*/
    --font-size-default: 1.6rem; /*1em;*/
    --font-size-lg: 2rem; /*1.25em;*/
    --font-size-xl: 2.4rem; /*1.5em;*/
    --font-size-2xl: 3.2rem; /*2em;*/
    --font-size-3xl: 4rem; /*2.5em;*/
    --font-size-4xl: 4.8rem; /*3em;*/
    --font-size-5xl: 6.4rem; /*4em;*/
    --font-size-6xl: 8rem; /*5em;*/
    --font-size-7xl: 9.6rem; /*6em;*/
    --border-width-none: 0rem; /*0em;*/
    --border-width-xs: 0.1008rem; /*0.063em;*/
    --border-width-default: 0.16rem; /*0.1em;*/
    --border-width-sm: 0.2rem; /* 0.125em;*/
    --border-width-lg: 0.4rem; /* 0.25em;*/
    --border-width-xl: 0.8rem; /*0.5em;*/
    --border-radius-none: 0rem; /*0em;*/
    --border-radius-sm: 0.2rem; /* 0.125em;*/
    --border-radius-default: 0.4rem; /* 0.25em;*/
    --border-radius-md: 0.8rem; /*0.5em;*/
    --border-radius-lg: 1.6rem; /*1em;*/
    --border-radius-pill: 50rem; /*31.25em;*/
    --shadow-level-1: 0rem 0.4rem 0.8rem; /*0em 0.25em 0.5em;*/
    --shadow-level-2: 0rem 0.8rem 2.4rem; /*0em 0.5em 1.5em;*/
    --shadow-level-3: 0rem 1.6rem 3.2rem; /*0em 1em 2em;*/
    --shadow-level-4: 0rem 1.6rem 4.8rem; /*0em 1em 3em;*/
    --spacing-inset-size-2xs: 0.4rem; /* 0.25em;*/
    --spacing-inset-size-xs: 0.8rem; /*0.5em;*/
    --spacing-inset-size-sm: 1.6rem; /*1em;*/
    --spacing-inset-size-lg: 2.4rem; /*1.5em;*/
    --spacing-inset-size-xl: 3.2rem; /*2em;*/
    --spacing-inset-size-2xl: 4rem; /*2.5em*/
    --spacing-size-5xs: 0.4rem; /* 0.25em;*/
    --spacing-size-4xs: 0.8rem; /*0.5em;*/
    --spacing-size-3xs: 1.6rem; /*1em;*/
    --spacing-size-2xs: 2.4rem; /*1.5em;*/
    --spacing-size-xs: 3.2rem; /*2em;*/
    --spacing-size-sm: 4rem; /*2.5em;*/
    --spacing-size-md: 4.8rem; /*3em;*/
    --spacing-size-lg: 5.6rem; /*3.5em;*/
    --spacing-size-xl: 6.4rem; /*4em;*/
    --spacing-size-2xl: 8rem; /*5em;*/
    --spacing-size-3xl: 12rem; /*7.5em;*/
    --spacing-size-4xl: 16rem; /*10em;*/
    --spacing-size-5xl: 20rem; /*12.5em;*/
}