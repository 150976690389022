:root {
    /* TODO slowly migrate to using celebration colors instead */
    /* Base Colors */
    --bg-page: #e6e9f0;

    --bg-primary: white;
    --bg-secondary: #292929;
    
    --bg-secondary-dark: #161616;/*maybe rename this var later*/

    --bg-secondary-accent-1: #242427;
    --bg-secondary-accent-2: #404040;

    --ui-primary: #ffc629;
    --ui-secondary: #f9dc95; /* Maybe use different secondary color */
    --ui-inverted: black;
    --ui-highlight: #ffd700;
    --ui-background: #2a2a2a;
    --ui-background-light: #999;

    --text-primary: #333;
    --text-secondary: #666666;
    --text-inverted: white;

    --text-ui-primary: black;
    --text-ui-inverted: white;

    --text-log-description: blue;

    --ui-loading-primary: #ffc629;
    --ui-loading-inverted: white;

    --text-link: '';

    --ui-progress: #337ab7;
    --ui-progress-2: #5cb85c;

    --ui-fivewhy: #8a6d3b; /* need a better name */
    --text-ui-fivewhy: white; /* --text-<the-above> */

    --ui-neutral: #4771e6; /* need a better name */
    --ui-neutral-2: #20409a;

    --ui-not-started: #909090;
    --ui-closed: #1a94e6;
    --ui-canceled-2: black;
    --ui-canceled: #5d5d5d;
    --ui-in-progress: #fcb802;
    --ui-ok: #ffc629; /*#00c155;*/
    --ui-ok-border: #f9dc95;
    --ui-warn: '';
    --ui-danger: #ff484f;
    --ui-danger-2: red;
    --ui-back: white;
    --ui-back-border: #ccc;
    --ui-shadow-light: #00000014;

    
    --bg-status-neutral: #f2f2f2;
    
    --bg-not-started: #d9d9d9;
    --bg-in-progress: #fff0de;
    --bg-delayed: #ffeaea;
    --bg-req-to-close: #e1fce9;
    --bg-canceled: #faeaff;
    --bg-closed: #eaecff;

    --border-neutral: #d8d8d8;

    --border-not-started: #bfbfbf;
    --border-in-progress: #ffd0ad;
    --border-delayed: #f3c0be;
    --border-req-to-close: #d0efbd;
    --border-canceled: #e0bef3;
    --border-closed: #c3bef3;

    --text-not-started: #0e0e0e;
    --text-in-progress: #853e00;
    --text-delayed: #7a130a;
    --text-req-to-close: #273f32;
    --text-canceled: #620a7a;
    --text-closed: #0a157a;

    --text-ui-closed: white;
    --text-ui-canceled-2: white;
    --text-ok: black;
    --text-warn: black;
    --text-danger: white;
    --text-neutral: #0e0e0e;

    /* Active State */
    --bg-primary-highlight: #f3f3f3;

    --text-primary-highlight: '';
    --text-secondary-highlight: '';

    --ui-primary-highlight: #f9dc95; /* Maybe use a different yellow for primary highligth and dim */
    --ui-secondary-highlight: '';
    --ui-inverted-highlight: #f9dc95;
    --ui-ok-highlight: #f9dc95;
    --ui-warn-highlight: '';
    --ui-danger-highlight: '';
    --ui-back-highlight: #f9dc95;

    /* Inactive State */
    --bg-primary-dim: '';
    --ui-primary-dim: #f9dc95;

    --text-primary-dim: #666666;
    --text-secondary-dim: '';

    --ui-secondary-dim: '';
    --ui-inverted-dim: '';

    --ui-neutral-dim: #7692e2;

    --ui-ok-dim: #f9dc95; /*#79c696;*/
    --ui-warn-dim: '';
    --ui-danger-dim: #ff9ea2;

    --skeleton-light-gradient: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
    --skeleton-dark-gradient: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
}


/* Color variables from Celebration a1/dark.css to overwrite default global a1/light.css on Celebration components as needed */
.clb-dark {
    --color-action-default: #f9dc95;
    --color-action-hover: #ffc629;
    --color-brand-100: #fcecc5;
    --color-brand-200: #f9dc94;
    --color-brand-300: #ffc629;
    --color-brand-400: #ebb01b;
    --color-brand-500: #bc850d;
    --color-brand-600: #87630c;
    --color-brand-gradient: linear-gradient(135deg, #ffc629 0%, #ebb01b 100%);
    --color-contrast-brand: #0e0e0e;
    --color-contrast-white: #ffffff;
    --color-contrast-black: #0e0e0e;
    --color-contrast-focused: #cea9ff;
    --color-feedback-danger-100: #ffeaea;
    --color-feedback-danger-200: #f3c0be;
    --color-feedback-danger-300: #c92323;
    --color-feedback-danger-400: #ae1e1e;
    --color-feedback-danger-500: #8e1818;
    --color-feedback-danger-600: #7a130a;
    --color-feedback-positive-100: #e1fce9;
    --color-feedback-positive-200: #d0efbd;
    --color-feedback-positive-300: #00b261;
    --color-feedback-positive-400: #08663b;
    --color-feedback-positive-500: #053821;
    --color-feedback-positive-600: #273f32;
    --color-feedback-warning-100: #fff0de;
    --color-feedback-warning-200: #ffd0ad;
    --color-feedback-warning-300: #ff9040;
    --color-feedback-warning-400: #db5c00;
    --color-feedback-warning-500: #943e00;
    --color-feedback-warning-600: #853e00;
    /* --color-neutral-100: #0E0E0E;    check this color */
    --color-neutral-100: #292929;
    --color-neutral-200: #666666;
    --color-neutral-300: #bfbfbf;
    --color-neutral-400: #d9d9d9;
    --color-neutral-500: #f2f2f2;
    --color-neutral-600: #ffffff;
    --color-neutral-background: #0e0e0e;
    --color-opacity-default: rgba(41, 41, 41, 0.32);
    --color-opacity-hover: rgba(255, 198, 41, 0.32);
    --color-opacity-disabled: rgba(102, 102, 102, 0.32);
    --color-opacity-error: rgba(174, 30, 30, 0.32);
}

/* Color variables from Celebration a1/light.css to use on components as needed (when a parent component has the default colors overwritten, for example)*/
.clb-light {
    --color-action-default: #ffc629;
    --color-action-hover: #fcecc5;
    --color-brand-100: #fcecc5;
    --color-brand-200: #f9dc94;
    --color-brand-300: #ffc629;
    --color-brand-400: #ebb01b;
    --color-brand-500: #bc850d;
    --color-brand-600: #87630c;
    --color-brand-gradient: linear-gradient(135deg, #ffc629 0%, #ebb01b 100%);
    --color-contrast-brand: #0e0e0e;
    --color-contrast-white: #ffffff;
    --color-contrast-black: #0e0e0e;
    --color-contrast-focused: #490c97;
    --color-feedback-danger-100: #ffeaea;
    --color-feedback-danger-200: #f3c0be;
    --color-feedback-danger-300: #c92323;
    --color-feedback-danger-400: #ae1e1e;
    --color-feedback-danger-500: #8e1818;
    --color-feedback-danger-600: #7a130a;
    --color-feedback-positive-100: #e1fce9;
    --color-feedback-positive-200: #d0efbd;
    --color-feedback-positive-300: #00b261;
    --color-feedback-positive-400: #08663b;
    --color-feedback-positive-500: #053821;
    --color-feedback-positive-600: #273f32;
    --color-feedback-warning-100: #fff0de;
    --color-feedback-warning-200: #ffd0ad;
    --color-feedback-warning-300: #ff9040;
    --color-feedback-warning-400: #db5c00;
    --color-feedback-warning-500: #943e00;
    --color-feedback-warning-600: #853e00;
    --color-neutral-100: #ffffff;
    --color-neutral-200: #d9d9d9;
    --color-neutral-300: #bfbfbf;
    --color-neutral-400: #666666;
    --color-neutral-500: #292929;
    --color-neutral-600: #0e0e0e;
    --color-neutral-background: #f2f2f2;
    --color-opacity-default: rgba(255, 255, 255, 0.32);
    --color-opacity-hover: rgba(252, 236, 197, 0.32);
    --color-opacity-disabled: rgba(191, 191, 191, 0.32);
    --color-opacity-error: rgba(255, 234, 234, 0.32);
}
