/* You can add global styles to this file, and also import other style files */

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~/src/assets/colors.css';

@import '~@celebration/assets/src/components/celebration.css';
@import '~@celebration/design-tokens/dist/css/at/light.css';
@import '~/src/assets/clb-sizes.css';

@font-face {
    font-family: Lato-Regular;
    src: url('assets/fonts/Lato-Regular.ttf');
}

body {
    font-family: Lato-Regular;
    font-size: 16px;
    background: #EDF0F7;
}

ul, h1, h2, h3, h4, h5, a, button {
    padding: 0;
    margin: 0;
    outline: none;
}

button {
    background: none;
    border: none;
}

li {
    list-style: none;
}

a:hover, a:focus {
    text-decoration: none;
}

/* container-fluid */
.container-fluid {
    padding: 20px;
    margin: 60px 0 0 90px;
}

/* columns */
.custom-padding {
    padding-left: 0;
}

.custom-margin {
    margin-bottom: 10px !important;
}

/* for-background */
.primary-bg {
    background: var(--bg-primary);
}

.secondary-bg {
    background: var(--bg-secondary);
}

/* for box */
.rounded-box {
    /*border-radius: 12px;*/
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 30px;
}

.rounded-box-custom-shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 30px 30px;
}

.scroll {
    overflow-x: auto;
}

.in-line-block {
    display: inline-block;
}

.title {
    font-size: 16px;
    margin-bottom: 20px;
}

.float-right {
    float: right;
}

.input-top-date {
    width: 160px;
}

.fill-box {
    min-height: calc(100vh - 100px);
}

.header {
    overflow: hidden;
    padding: 14px 11px;
    margin: 0 0 5px 0;
}

.header h1 {
    display: inline-block;
    font-size: 1.125em;
}

.header h2 {    
    font-size: 0.8em;
}

.header h1,
.rounded-box.primary-bg .header h1 {
    color: var(--text-secondary);
}

.rounded-box.secondary-bg .header h1 {
    color: var(--text-inverted);
}

.rounded-box .body {
    padding: 15px 15px;
}

.rounded-box li {
    display: block;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
    padding-top: 10px;
}

.rounded-box ul {
    padding-bottom: 15px;
}

/* for action buttons */
.action-button {
    background: var(--ui-primary);
    border: 1px solid transparent;
    border-radius: 4px;
    display: inline-block;
    color: var(--text-ui-primary);
    line-height: 34px;
    padding: 0 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 30px;
}

/* for changing button color on hover */
.action-button:hover {
    background: var(--ui-primary-highlight);
    color: var(--text-ui-primary);
}

.action-button-notifications {
    padding: 7px;
    color: var(--text-ui-primary);
}

.action-button-shadowless {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.action-button.button-fat {
    padding-top: 5px;
    padding-bottom: 5px;
}

.action-button.disabled {
    background: var(--ui-primary-dim);
    color: var(--text-primary-dim);
    cursor: default !important;
}

.action-button[disabled] {
    background: var(--ui-primary-dim);
    color: var(--text-primary-dim);
    cursor: not-allowed !important;
}

.action-button.button-save {
    background-color: var(--ui-ok);
    color: var(--text-ok);
}
.action-button.button-save[disabled], .action-button.button-save.disabled {
    background-color: var(--ui-ok-dim);
    color: var(--text-primary-dim);
}

.action-button.button-save:hover {
    background-color: var(--ui-primary-highlight);
}

.action-button.button-insert {
    padding-top: 3px;
    padding-bottom: 3px;
}

.action-button.button-edit {
    background-color: var(--ui-ok);
    color: var(--text-ok);
}
.action-button.button-edit:hover {
    background-color: var(--ui-primary-highlight);
}

.action-button.button-back {
    background-color: var(--ui-back);
    color: var(--text-ui-primary);
    border: 1px solid var(--ui-back-border);
}

.action-button.button-back:hover {
    background-color: var(--ui-back-highlight);
    border-color: var(--ui-back-highlight);
}

.action-button.button-back[disabled], .action-button.button-back.disabled  {
    background-color: var(--ui-back);
    border-color: var(--ui-back-border);
    cursor: default;
}

.action-button.button-delete {
    background-color: var(--ui-danger);
    color: var(--text-danger);
}

.action-button.button-delete[disabled], .action-button.button-delete.disabled  {
    background: var(--ui-danger-dim) !important;
    cursor: default;
}

.action-button.button-edit[disabled], .action-button.button-edit.disabled {
    background-color: var(--ui-ok-dim) !important;
    cursor: default;
}

.action-button.button-grey {
    background: #9c9c9c;
}

.button-block {
    width: 100%;
}

/* for white buttons*/
.white-button {
    background: #fff;
    border: solid 1px #E1E1E1;
    border-radius: 4px;
    min-width: 34px;
    min-height: 34px;
    display: inline-block;
    color: #666666;
    line-height: 34px;
    padding: 0 10px;
    margin: 2px;

    -webkit-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 30px -2px rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 30px;
}

.white-button-shadowless {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.white-button.button-selected {
    background-color: #f5f5f5;
}

/* generic style for forms controls (input, textarea, radio, etc...) */
form input, form select, form textarea {
    display: block;
    height: 34px;
    line-height: 1.42857143;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #666666;
    font-size: 0.875em;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

form input[disabled], form select[disabled] {
    background-color: #d6d6d6;
}

input[disabled], select[disabled] {
    background-color: #d6d6d6;
}

form select {
    background-color: #f8f8f8;
}

form input[type=date], form input[type=month] {
    padding: 6px 6px;
}

form input[type=checkbox] {
    height: 13px;
    display: inline-block;
    width: auto;
}

form input[type=radio] {
    height: 13px;
    display: inline-block;
    width: auto;
}

form textarea {
    height: 70px;
    resize: none;
}

form label, span.upload-file-name {
    font-size: 0.8125em;
    color: #666666;
    display: block;
    margin-bottom: 4px;
    font-weight: normal;
}

span.upload-file-name {
    overflow: hidden;
    text-overflow: ellipsis;
}

.widthAuto {
    width: auto !important;
    display: inline-block;
    padding-right: 5px;
    margin-right: 5px;
}

/* for user picture */
.user-picture {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 0 15px;
    border: solid 1px #ccc;
    background-color: #DDDDDD;
}

.user-picture-align label, .user-picture-align i {
    line-height: 40px !important;
    padding-top: 10px;
    padding-right: 10px;
}

.user-participant-actions {
    margin-right: 15px;
}

.glyphicon-user-white {
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.glyphicon-user-blue {
    color: var(--ui-neutral);
}

.glyphicon-remove-red {
    color: var(--ui-danger-2)
}

.pointer {
    cursor: pointer;
}

/* for data table */
table {
    font-size: 0.8125em;
    width: 100%;
}

table.break-word {
    table-layout:fixed;
}

table thead tr th {
    font-weight: normal;
    text-transform: uppercase;
}

table tr {
    border-bottom: 1px solid #f6f6f8;
}

table tr:last-child {
    border: none;
}

table thead tr th,
table tbody tr td {
    padding: 20px;
}

table.break-word tbody tr td {
    word-wrap:break-word;
}

.table {
    margin-top: 10px;
}

.table tr {
    line-height: 50px;
    min-height: 50px;
}

.table tr td {
    border-top: 1px solid #edf0f7;
    line-height: 50px;
    min-height: 50px;
    color: #666666;
    vertical-align: middle;
}

.table > thead > tr > th {
    vertical-align: middle;
    border-bottom: 1px solid #edf0f7;
}

.table > thead > tr > th.actions {
    width: 10em;
    text-align: center;
}

.no-padding {
    padding: 0px !important;
}

.no-padding header {
    padding-left: 20px;
}

.no-padding table thead tr th:first-child,
.no-padding table tbody tr td:first-child,
.no-padding table tfoot tr td:first-child {
    padding-left: 25px;
}

.no-padding table thead tr th:last-child,
.no-padding table tbody tr td:last-child,
.no-padding table tbody tr td:last-child {
    padding-right: 25px;
}

.uppercase {
    text-transform: uppercase;
}

.name-participant {
    overflow: hidden;
    max-width: 72%;
    max-height: 50px;
    white-space: nowrap;
}

/* TEMP for tabs */
nav.tabs {
    overflow: hidden;
    height: 42px;
    padding: 0 10px 0 10px;
    border-bottom: 1px solid #e1e1e1;
}

nav.tabs ul li {
    display: inline-block;
}

nav.tabs ul li a {
    display: block;
    text-align: center;
    background: #eaeaea;
    color: #707070;
    line-height: 42px;
    width: 70px;
    border-radius: 5px 5px 0 0;
}

nav.tabs ul li a:hover,
nav.tabs ul li a.active {
    background: #4771e6;
    color: #fff;
}

/* TEMP  for dialogs */
.modal-dialog {
    z-index: 1000;
    position: fixed;
    right: 0;
    left: 0;
    top: 20px;
    margin-right: auto;
    margin-left: auto;
    min-height: 200px;
    /*max-width: 520px;*/
    background-color: #fff;
    padding: 12px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 850px;
}

.modal-content {
    box-shadow: none;
    border: 0px;
}

.modal-header {
    border: 0px;
}

.modal-header button.close {
    color: #000;
    opacity: 0.5;
    margin: -20px;
}

.modal-header button.close span {
    font-size: 2em;
    font-weight: 300;
}

.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
}

/* for use in lists */
.default-list li {
    display: block;
    background: #eaeaea;
    border-left: 5px solid #c0c0c0;
    border-bottom: none;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding: 10px;
}

.li-danger {
    border-left: 5px solid #a94442 !important;
    background: #f2dede !important;
}

.li-warning {
    border-left: 5px solid #8a6d3b !important;
    background: #fcf8e3 !important;
}

.li-success {
    border-left: 5px solid #3c763d !important;
    background: #dff0d8 !important;
}

.liDescription {
    color: #A2A2A2;
    font-size: 11px;
}

.liText {
    font-size: 13px;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: normal;
}

.default-list li div {
    width: 10%;
    min-width: 150px;
}

.default-list li p {
    padding: 0px;
    margin: 0px;
}

.searchTerm {
    width: 100%;
    background-image: url('assets/images/icon_search.png');
    background-repeat: no-repeat;
    background-position: 15px 5px;
    text-indent: 40px;
    border-radius: 25px;
    border: 2px solid #e1e1e1;
    line-height: 40px;
    height: 40px;
}

.list-content {
    padding: 10px;
    color: #666666;
}

ul.top-tab {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 0;
    margin-bottom: 15px;
}

ul.top-tab li.nav-item {
    border-bottom: 0px;
    padding-bottom: 0px;
}

ul.sub-tab,
ul.sub-tab li.nav-item {
    border-bottom: 0px;
    padding-bottom: 0;
    margin-bottom: 15px;
}

.row-users {
    color: #666666;
    margin: 0px;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid white;
}

.quesiton-choice .row-users {
    display: flex !important;
}

.quesiton-choice .row-users .user-picture-align .name-participant {
    text-overflow: ellipsis;
}

.list-inline > li {
    display: inline-block !important;
    border: 0px;
}

.width100 {
    width: 100%;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 37px;
    left: 2px;
    right: 0;
    min-width: 260px;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    z-index: 1000;
}

.dropdown-content div {
    padding: 12px 16px;
    display: block;
}

.dropdown-content div:hover {
    background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn {
    cursor: pointer;
}

.dropdown-user-name {
    display: none;
    position: absolute;
    top: 45px;
    left: 45px;
    right: 0;
    min-width: 150px;
    width: max-content;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    z-index: 1000;
}

.dropdown-user-name div {
    padding: 12px 16px;
    display: block;
}

.dropdown-user-name div:hover {
    background-color: #f1f1f1
}

.dropdown:hover .dropdown-user-name {
    display: block;
}

.align-right {
    text-align: right;
}

.fade {
    display: none;
}

.has-error {
    border: 1px solid red;
}

/*Hide input that change day, month and year*/
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=month]::-webkit-inner-spin-button,
input[type=month]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
}

input[type=date], input[type=month] {
    position: relative;
}

input[type=date]::after,
input[type=month]::after {
    font-family: 'Glyphicons Halflings' !important;
    content: '\e109' !important;
    color: #c7c7c7;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 1.3em;
    z-index: 0;
}

input[type=date]::-webkit-calendar-picker-indicator,
input[type=month]::-webkit-calendar-picker-indicator {
    font-size: 2em;
    color: transparent;
    padding: 10px;
    margin: 0;
    z-index: 9;
    background-color: transparent;
    cursor: pointer;
}

.date-previous {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.date-next {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.font-color-red {
    color: red;
}

.wrapword {
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /*Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
}
.without-top-left-radius {
    border-top-left-radius: 0 !important;
}

.glyphicon-3x {
    font-size: 3em;
}

.glyphicon-unchecked.top {
    font-size: 18px;
    display: block;
    margin-left: -4px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-text-stroke: 1px white;
}

.glyphicon-unchecked.bottom {
    font-size: 18px;
    display: block;
    margin-left: 3px;
    margin-top: -16px;
    margin-bottom: 6px;
    margin-right: -5px;
    -webkit-text-stroke: 1px white;
}

.introjs-tooltip {
    background-color: #20409a;
    color: white;
}

.introjs-helperNumberLayer {
    right: 5px;
    left: unset;
    top: 5px;
}

.introjs-arrow.top {
    border-bottom-color: #20409a;
}
.introjs-arrow.right {
    border-left-color: #20409a;
}
.introjs-arrow.bottom {
    border-top-color: #20409a;
}
.introjs-arrow.left {
    border-right-color: #20409a;
}

.introjs-overlay{
    opacity: 0.4 !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .introjs-fixParent {
      position: absolute !important;
    }
}

.shepherd-element{
    z-index: 999999;
}

.shepherd-header{
    line-height: 1.2em;
}

.shepherd-footer {
    align-items: center;
}
    
      
.shepherd-progress {
    font-size: .8rem;
    margin-right: auto;
}

.shepherd-checkbox{
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
    margin-right: auto;
}

.shepherd-checkbox input{
    margin-right: 4px;
}


.shepherd-text{
    font-size: 1em;
}

.shepherd-button{
    color: white;
}

.shepherd-button:not(:disabled):hover{
    color: white
}

.inner-shadow {
    box-shadow: inset 0px 0px 25px #666666;
}

.glyphicon.normal-right-spinner {
    -webkit-animation: glyphicon-spin-r 2s infinite linear;
    animation: glyphicon-spin-r 2s infinite linear;
}

@-webkit-keyframes glyphicon-spin-r {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes glyphicon-spin-r {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.inner-shadow-red {
    box-shadow: inset 0px 0px 25px #a94442;
}

.inner-shadow-yellow {
    box-shadow: inset 0px 0px 25px #FCB802;
}

.inner-shadow-green {
    box-shadow: inset 0px 0px 25px #3c763d;
}

.inner-shadow-blue {
    box-shadow: inset 0px 0px 25px #0194e6;
}

.help_img_gif{
    width:100%;
}


.routines .MultiSelect__Component {
    position: relative;
    margin-bottom: 10px;
    
}

.indicator-edit {
    width: 70px;
    border: 1px solid #d6d3d3;
    border-radius: 4px;
    top: 3px;
    position: relative;
    padding: 3px;
}

@media(max-width: 992px) {
    /* Responsivity for forms */
    .custom-padding{
        padding-left: 15px;
    }

    .button-delete-float-right{
    float: right;
    }
}

.index-new-format {
    margin-top: 30vh;
    font-family: sans-serif; 
    font-size: 1.5em; 
    font-weight: bold; 
    color: var(--ui-primary); 
    text-align: center;
}

.post-message-left-div .ck-toolbar_grouping {
    background: #fff!important;
    border: none!important;
    position: relative!important;
    top: 99px;
}

.post-message-left-div .ck-editor__main {
    position: relative!important;
    bottom: 40px;
}

.post-message-left-div .ck-editor__editable  {
    height: 100px;
} 

@media only screen and (min-width: 1025px) {
    .post-message-left-div .ck-editor {
        padding-left: 12px!important;
    }
}

@media only screen and (min-width: 1025px) {
    .post-message-left-div .ck-editor__editable  {
        width: 125.4%!important;
    } 

    .ck.ck-editor {
        width: 90%!important;
    }

}

@media only screen and (min-width: 1920px) {
    .post-message-left-div .ck-editor__editable  {
        width: 118.4%!important;
    } 

    .ck.ck-editor {
        width: 97%!important;
    }

}

@media only screen and (max-width: 1025px) {
    .ck.ck-editor {
        padding: 10px!important;
    }
}

.marker-pink {
    background: #fc7899!important;
}

.marker-yellow {
    background: #ff0!important;    
}

.marker-blue {
    background: #72ccfd!important;
}

.marker-red {
    background: #f44336!important;
}

.marker-green {
    background: #62f962!important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  input[type="month"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }


.message li {
    list-style: inherit!important;
    border-bottom: none !important;
    padding-bottom: 0px;
}

.message ul {
    list-style: disc!important;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
    margin: 0px !important;
    /* set to 0 if your not using a list-style-type */
    padding-left: 20px;
    padding-bottom: 0px;
}

.message ul li {
    list-style: disc!important;
    display: list-item!important;
}

.message ol li {
    list-style: decimal!important;
    display: list-item!important;
}

.message ol {
    list-style: disc!important;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
    margin: 0px !important;
    /* set to 0 if your not using a list-style-type */
    padding-left: 20px;
    padding-bottom: 0px;
}


.color-font-green {
    color: #4CE64C!important
}

#grow-bigger .ql-editor {
    height: 95px !important;
    border-top: 1px solid #ccc;
}

#grow-bigger:focus-within .ql-editor{
    height: 445px !important;
    position: relative;
    background-color: white;
    z-index: 1;
} 

#grow-bigger:focus-within{
    top: -350px !important;
}

::-webkit-scrollbar-corner{
    background-color: #0000;
}

::-webkit-scrollbar-thumb{
    background-color: var(--ui-primary);
}

::-webkit-scrollbar{
    width: 14px;
    height: 6px;
}
#grow-bigger .ql-toolbar.ql-snow {
    border: none !important;
}

#grow-bigger .ql-container{
    width: 98% !important;
}

.MultiSelect_Options__Item__Td {
    float: left;
}

.MultiSelect__Filter__Icon {
    right: 5px;
}

.MultiSelect__Filter__Input {
    padding-left: 0;
}

#grow-bigger .ql-toolbar.ql-snow {
    border: none !important;
}

#grow-bigger .ql-container{
    width: 98% !important;
}

.message .ql-indent-1{
    margin-left: 10px;
}
.message .ql-indent-2{
    margin-left: 20px;
}
.message .ql-indent-3{
    margin-left: 30px;
}
.message .ql-indent-4{
    margin-left: 40px;
}
.message .ql-indent-5{
    margin-left: 50px;
}
.message .ql-indent-6{
    margin-left: 60px;
}
.message .ql-indent-7{
    margin-left: 70px;
}
.message .ql-indent-8{
    margin-left: 80px;
}

.ql-editor li {
    border-bottom: none !important;
}

.ql-tooltip {
    z-index: 2;
    left: 0 !important;
    top: -300 !important;
}


.chart-wrapper-icon:before {
    content: "";
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 15px solid white;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid white;
}

.nps-text-area {
    min-height: 80px !important;
    max-height: 80px !important;
}

.skeleton-light {
    display: inline-block;
    border-radius: 4px;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: var(--bg-status-neutral);
}
.skeleton-light::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-110%);
    background-image: var(--skeleton-light-gradient);
    animation: shimmer 2s infinite;
    content: '';
}

.skeleton-dark {
    display: inline-block;
    border-radius: 4px;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: var(--color-neutral-200);
}
.skeleton-dark::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-110%);
    background-image: var(--skeleton-dark-gradient);
    animation: shimmer 2s infinite;
    content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(110%);
  }
}

.clb-icon--sm {
    font-size: 1.6rem;
}
.clb-icon--md {
    font-size: 2.4rem;
}
.clb-icon--lg {
    font-size: 3.2rem;
}

/* This removes the black line and shadow on the right side of screen when a clb drawer is closed */
aside.clb-drawer:not(.clb-drawer--open) {
    transform: translate(101%);
    box-shadow: none;
}